/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center h-12 w-12 mt-2 mb-2
      mx-auto shadow-lg bg-gray-900 text-green-500 hover:bg-green-600 hover:rounded-xl rounded-3xl hover:text-gray-100 transition-all duration-300 ease-linear cursor-pointer;
  }
  .sidebar-icon-logo {
    @apply relative flex items-center justify-center h-12 w-12 mt-2 mb-2
      mx-auto shadow-lg bg-gray-900  hover:bg-gray-600 hover:rounded-xl rounded-3xl  transition-all duration-300 ease-linear cursor-pointer;
  }
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
}
/* Sanity Block Content Article Styling */

.article p {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.article h1 {
  font-size: 2rem;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  font-weight: bold;
}
.article img {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.article li {
  list-style: disc;
  margin-left: 2rem;
}

.article a {
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

@media (max-width: 767px) {
  .article h1 {
    font-size: 1.5rem;
  }
}

/* Sanity Block Content Review Styling */
